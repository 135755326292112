import React from 'react'
import styled from 'styled-components/macro';

const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border-radius: 0.8rem;
  margin-bottom: 0.7rem;
  margin-top: 0.7rem;
  padding: 0 3.2rem;
  color: white;
  background-color: rgb(0, 160, 0);
  background-color: color(display-p3 0 0.62 0);
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  opacity: ${props => props.disabled ? 0.5 : 1};
  &:hover {
      opacity: 0.8;
  }
`

const Button = React.forwardRef((props, ref) => {
    return (
        <ButtonWrapper ref={ref} {...props}>{props.children}</ButtonWrapper>
    )
});

export default Button;