export const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    lightGray: "#D8D8D8"
  },
  breakpoints: {
    xs: "360px",
    sm: "600px",
    md: "992px",
    lg: "1200px",
  },
  fontSizes: {
    xs: "0.75rem", //12px
    sm: "0.875rem", //14px
    md: "1rem", //16px
    lg: "1.125rem", //18px
    xl: "1.25rem", //20px
    xl2: "1.5rem", //24px
    xxl: "2.25rem", //36px
  },
  fontWeights: {
    normal: 400,
    semiBold: 500,
    bold: 600,
    bolder: 700
  },
  border: {
    "10px": "0.625rem",
  },
  width: {
    main: "83.5rem", // 1336px
  },
  spacing: {
    md: "1.5rem",
    md2: "2rem",
    lg: "3.5rem",
    big: "3.75rem",
  },
  zIndex: {
    "1st": 0,
    "2nd": 1,
    "3rd": 2,
    "4th": 3,
    "5th": 4,
    "6th": 5,
    "7th": 6,
    "8th": 7,
    "9th": 8,
    "10th": 9,
  },
}