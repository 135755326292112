import Header from './Header';
import Teaser from './Teaser';
import Clients from './Clients';
import Contact from './Contact';
import Features from './Features';
import Footer from './Footer';
import * as Styles from './styled';

const Home = () => {
  return (
    <Styles.HomeMain>
      <Styles.HomeContent>
        <Header />
        <Styles.Divider />
        
        <Teaser />
        <Styles.Divider />

        <Features />
        <Styles.Divider />

        <Clients />
        <Styles.Divider />

        <Contact />
        <Footer/>
      </Styles.HomeContent>
    </Styles.HomeMain>
  )
}

export default Home