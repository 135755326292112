import * as Styles from './styled';


const Footer = () => {
    return (
        <Styles.Wrapper>
            &copy; {new Date().getFullYear()} myWebAisle. All Rights Reserved.
        </Styles.Wrapper>
    )
}

export default Footer;