import TeaserImg from 'src/img/teaser.png'
import * as Styles from './styled';

const Teaser = () => {
  return (
    <Styles.Wrapper>
      <Styles.Img src={TeaserImg} alt="teaser" />
    </Styles.Wrapper>
  )
}

export default Teaser;