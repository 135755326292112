import React from 'react'
import styled from 'styled-components/macro';

 const InputWrapper = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.8rem;
  width: calc(100% - 2rem);
  border-radius: 0.8rem;
  margin-bottom: 0.7rem;
  padding: 0 1rem;
  color: rgb(0, 160, 0);
  color: color(display-p3 0 0.62 0);
  background-color: rgb(193, 241, 193);
  background-color: color(display-p3 0.757 0.945 0.757);
  font-weight: semi-bold;
  border: none;
  font-size: 1.125rem;
  &:focus {
    box-shadow: 0 0 0 2px rgb(0, 160, 0);
    box-shadow: 0 0 0 2px color(display-p3 0 0.62 0);
    outline: none;
  };
  ::placeholder {
    color: rgb(0, 160, 0);
    color: color(display-p3 0 0.62 0);
    opacity: 0.7
  };
  h1, h2 {
    margin: 0;
  }
`


const Input = React.forwardRef((props, ref) => {
    return (
        <InputWrapper ref={ref} {...props} />
    )
});

export default Input;