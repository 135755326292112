import { useState } from 'react';
import Input from '../../Input';
import Button from '../../Button';
import * as Styles from './styled';

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [store, setStoreName] = useState('');
  const [storeNo, setStoreNo] = useState('');
  const [website, setWebsite] = useState('');
  const [comment, setComment] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/mwa/contact`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        email,
        message: `${store} has requested a demo.\n 
          Phone #: ${phone} \n
          Number of Locations: ${storeNo} \n
          Website: ${website} \n
          Comments: ${comment} \n
        `,
      })
    })

    if (response.status === 200) {
      alert('Thank you! We\'ll reach out to you soon.');
      setName('')
      setPhone('')
      setEmail('')
      setStoreName('')
      setStoreNo('')
      setWebsite('')
      setComment('')
    } else {
      alert('There was an error sending your request. Please check your connection and try again.');
    }
    setLoading(false);
  }

  return (
    <Styles.Wrapper>
      <div id="header">
        <h1>Go Live in 2 Weeks.</h1>
        <h2>Contact Us Today to Schedule a Demo</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div id="inner">
          <Styles.InputGroup>
            <h2>About You</h2>
            <div>
              <div className="input-row">
                <Input type="text" value={name} placeholder="Name" name="name" autocomplete="name" onChange={(e) => setName(e.target.value)} required/>
                <Input type="text" value={phone} placeholder="Phone" name="phone" autocomplete="tel" pattern='^(\+)?([ 0-9]){10,16}$' onChange={(e) => setPhone(e.target.value)} required/>
              </div>
              <Input type="text" value={email} placeholder="Email" name="email" autocomplete="email" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}" onChange={(e) => setEmail(e.target.value)}  required/>

            </div>
          </Styles.InputGroup>

          <Styles.InputGroup>
            <h2>Your Store</h2>
            <div>
              <Input type="text" value={store} placeholder="Name of Store" name="store_name" autocomplete="name" onChange={(e) => setStoreName(e.target.value)} required/>
              <div className="input-row">
                <Input type="number" value={storeNo} placeholder="Number of Locations" name="locations_no" min="1" onChange={(e) => setStoreNo(e.target.value)} required/>
                <Input
                  type="text"
                  value={website}
                  placeholder="Existing Website"
                  name="existing_website"
                  pattern="/https?:\/\/w{0,3}\w*?\.(\w*?\.)?\w{2,3}\S*|www\.(\w*?\.)?\w*?\.\w{2,3}\S*|(\w*?\.)?\w*?\.\w{2,3}[\/\?]\S*/"
                  onChange={(e) => setWebsite(e.target.value)}
                  required
                />
              </div>
            </div>
          </Styles.InputGroup>

          <Styles.InputGroup>
            <h2>Anything else you'd like to share?</h2>
            <div>
              <textarea value={comment} name="anything_else" placeholder="Comments" onChange={(e)=> setComment(e.target.value)}></textarea>
            </div>
          </Styles.InputGroup>
        </div>
        <div id="button-group">
          <Button type="submit" disabled={loading}>{loading ? "Submitting" : "Request A Demo"}</Button>
        </div>
      </form>
    </Styles.Wrapper>
  )
}

export default Contact;