import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid rgba(0,0,0,0.1);
  color: ${props => props.color ? props.color : "black"};
  h1 {
    font-size: 2.5rem;
  };
  h2 {
    font-size: 2.063rem;
  }
  h1, h2 {
    margin: 0;
    color: rgb(0, 160, 0);
    color: color(display-p3 0 0.62 0);
    font-family: 'Nunito', 'Roboto', sans-serif;
  };
  background-color: rgb(238, 255, 238);
  background-color: color(display-p3 0.933 1 0.933);
  padding: 1.5rem 0;
  #header {
    text-align: center;
    margin-bottom: 1.5rem;
  };
  #inner {
    max-width: 30rem;
    margin: 0 1rem;
  }
  #button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`

export const InputGroup = styled.div`
  margin: 1rem auto;
  h2 {
    font-size: 1.2rem;
    margin-bottom: 6px;
    color: rgb(0, 160, 0);
    color: color(display-p3 0 0.62 0);
    margin-left: 0.5rem;
    font-family: 'Avenir Next', 'Roboto', sans-serif;
    font-weight: 600;
  }
  .input-row {
    display: grid;
    flex-direction: row;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (max-width: 650px) {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
  textarea {
    height: 7rem;
    width: calc(100% - 2rem);
    resize: none;
    color: rgb(0, 160, 0);
    color: color(display-p3 0 0.62 0);
    font-weight: semi-bold;
    background-color: rgb(193, 241, 193);
    background-color: color(display-p3 0.757 0.945 0.757);
    border: none;
    font-size: 1.2rem;
    padding: 1rem;
    border-radius: 0.8rem;
    &:focus {
      box-shadow: 0 0 0 2px rgb(0, 160, 0);
      box-shadow: 0 0 0 2px color(display-p3 0 0.62 0);
      outline: none;
    };
    ::placeholder {
      color: rgb(0, 160, 0);
      color: color(display-p3 0 0.62 0);
      opacity: 0.7
    };
  }
`