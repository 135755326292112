import Hero from 'src/img/hero.png'
import * as Styles from './styled';

const Header = () => {
  return (
    <Styles.HeaderWrapper>
      <Styles.Hero src={Hero} alt="hero" />
    </Styles.HeaderWrapper>
  )
}

export default Header;