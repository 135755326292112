import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(0, 160, 0);
  color: color(display-p3 0 0.62 0);
  background-color: rgb(238, 255, 238);
  background-color: color(display-p3 0.933 1 0.933);
  padding-bottom: 2rem;
  border-radius: 0 0 4px 4px;
  font-size: 0.75rem;
  font-weight: bold;
`