import styled from 'styled-components/macro';

export const HomeMain = styled.main`
  background-color: ${props => props.theme.colors.lightGray};
  min-height: 100vh;
  padding-bottom: ${props => props.theme.spacing.big};  
`

export const HomeContent = styled.div`
  background-color: white;
  max-width: 64rem; // 1024px
  margin: 0 auto;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 30px rgba(0,0,0,0.1);
  background-clip: padding-box;
`

export const Divider = styled.div`
  height: 6px;
  border-right: none;
  border-left: none;
  width: 100%;
  margin-top: -1px;
  margin-bottom: -1px;
  position: relative;
  background-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.1);
`