import ClientsImg from 'src/img/clients.png'
import * as Styles from './styled';

const Clients = () => {
  return (
    <Styles.Wrapper>
      <Styles.Img src={ClientsImg} alt="clients" />
    </Styles.Wrapper>
  )
}

export default Clients;