import FeaturesImg from 'src/img/features.png'
import * as Styles from './styled';

const Features = () => {
  return (
    <Styles.Wrapper>
      <Styles.Img src={FeaturesImg} alt="features" />
    </Styles.Wrapper>
  )
}

export default Features;